import React from 'react'
import kingsizebed from "../images/king size bed.jpg";
import woodbed from "../images/bed.jpg";

function Kingbed() {
    return (
        <div>
            <div className="album py-0">
                <div className="container">
                    <div className="row ">
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <img src={kingsizebed} className="card-img-top" alt="Product- King Size Bed Full Box (Hydrolic system)"/>
                                <div className="card-body">
                                    <h5 className="card-title">Product- King Size Bed Full Box (Hydrolic system)</h5>
                                    <p className="card-text">Wood- Teak wood</p>
                                    <a href="tel: 9851512851" className="Bookcall">Book Now</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3">
                            <div className="card" >
                                <img src={woodbed} className="card-img-top" alt="Product- King Size Bed Full Box (Hydrolic system)"/>
                                <div className="card-body">
                                    <h5 className="card-title">Product- King Size Bed Full Box (Hydrolic system)</h5>
                                    <p className="card-text">Wood- Teak wood</p>
                                    <a href="tel: 9851512851" className="Bookcall">Book Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Kingbed