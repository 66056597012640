import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import "./Main.css";
import ScrollToTop from "react-scroll-to-top"
import Mapgoogle from './Mapgoogle';
import fingerjointboard from "../images/fingerdoor-design.jpg";
import Woodenfloor from "../images/woodenfloorchoice.jpg";
import Fingerdoor from './Fingerdoor';
import ReactWhatsapp from "react-whatsapp";
import Woodenpage from './Woodenpgae';
import rmi1 from "../images/RMI WOODY.pdf"
import rmi2 from "../images/cover rmi.pdf";
import VideoPage from './VideoPage';
import teamrmwoody from "../images/rm-woody Team.jpg";
import Kingbed from './Kingbed';


function Main() {
  return (
    <div>
      <Navbar />
      <br />
      <br />


      <div className="album py-3">
        <div className="container">
          <h5>R M International</h5>
        </div>
      </div>

      <div className="album py-3">
        <div className="container">
          <div className="row ">
            <div className="col-md-8 mt-2">
              <Slider />

            </div>
            <div className="col-md-4 mt-2">
              <div className="card text-dark bg-light mb-3">
                <div className="card-header"><center><b style={{ color: "darkBlue" }}>PRODUCTS LINK</b></center></div>
                <div className="card-body">
                  <a href='wood-bed'>
                  <h5 className="card-title mb-3">WOOD BED <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"/>
  <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></h5></a> 
                  <a href='Contact-us'>
                  <h5 className="card-title">CONTACT US<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"/>
  <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></h5></a> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ color: "Green" }} class="fixed-bottom "> <ReactWhatsapp number="+91  9851512851" className="whatsappbutton" message="I am Interested"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
      </svg> Whatsapp  <div className="spinner-grow text-light spinner-grow-sm" role="status">
          <span className="visually-hidden"> Loading...</span>
        </div></ReactWhatsapp></div>
      <ScrollToTop smooth top="100" color="Darkblue" />
      <div className='backcolor'>
        <div className="album py-0">
          <div className="container">
            <marquee><h5 className='mt-3' style={{ color: "white" }} >Welcome To RM International, Finger Joint Board & Wooden Floor Manufacture Company </h5></marquee>
          </div>
        </div>
      </div>
      <br />


      <div className="album py-2">
        <div className="container">
          <div className="row ">
            <div className="col-md-6">


              <div className="card-body">
                <div className='colortext'>
                  <h3 className="card-title">Welcome to <b>R M International</b></h3>
                </div>
                <p className="card-text justify">At R M International, we specialize in manufacturing high-quality solid wood finger joint boards, frames, and furniture crafted with precision and durability. Our wood undergoes chemical treatment and seasoning, ensuring long-lasting strength and superior performance.</p>
                <p>
                  We take pride in offering a wide range of premium wood types, including</p>
                <p className='mb-1'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                </svg> Teak Wood – Renowned for its strength and elegance</p>
                <p className='mb-1'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                </svg> Mahogany Wood – A classic choice for fine furniture</p>
                <p className='mb-1'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                </svg> Mango Wood – Sustainable and stylish</p>
                <p className='mb-3'><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                  <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
                </svg> Tanzanian Sal Wood – Exceptionally durable and reliable
                </p>
                <p>Explore our collection and experience the finest in wood craftsmanship.
                </p>
                <h6>For inquiries and orders, feel free to <b><a href='Contact-us'>contact us!</a></b></h6>
              </div>




            </div>
            <div className="col-md-6">
              <img src={teamrmwoody} className="card-img-top" alt="teamrmwoody" />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="album py-0">
        <div className="container">
          <div className='colortext'>
            <h2>Video <b>Explore</b></h2>
          </div>
        </div>
      </div>
      <VideoPage />
      <hr />
      <div className="album py-0">
        <div className="container">
          <div className='colortext'>
            <h2> <b>Explore</b> Bed Products</h2>
          </div>
        </div>
      </div>
      <Kingbed/>
      <br/>
      <div className="album py-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex justify-content-start align-items-center mb-2">
                <div className="col-md-12 me-2 mt-3">

                  <div className="card">

                    <div className="card-body">
                      <h5 style={{ color: "black" }} className="card-title mb-1">RMI Woody Catalouge 1</h5>

                      <p className="card-text">RMI WOODY, FINEST WOOD FOR YOUR INTERIOR</p>
                      <a href={rmi2} className="btn-grad15"><small>Download Catalouge <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-cloud-arrow-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708z" />
                        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                      </svg></small> </a>


                    </div>
                  </div>

                </div>

                <div className="col-md-12  mt-3">
                  <div className="card">

                    <div className="card-body">
                      <h5 className="card-title mb-1" >RMI Woody Catalouge 2</h5>
                      <p className="card-text">RMI WOODY, FINEST WOOD FOR YOUR INTERIOR</p>

                      <a href={rmi1} className="btn-grad15"><small>Download Catalouge <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-cloud-arrow-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M7.646 10.854a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 9.293V5.5a.5.5 0 0 0-1 0v3.793L6.354 8.146a.5.5 0 1 0-.708.708z" />
                        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                      </svg></small> </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="album py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <a href='finger-joint-board'>
                  <img src={fingerjointboard} className="card-img-top" alt="fingerjointboard" /></a>
                <div className="card-body">
                  <h5 className="card-title">Finger Joint Board</h5>
                  <p style={{ color: "grey" }} className="card-text">For use all type of furniture, door and window</p>
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Benefits of Use
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">

                          <p className='mb-1 texts'>1.Immune to Decay: It is resistant to the attack of fungus, white ants and inscets.</p>
                          <p className='mb-1 texts'>2. It gives a flawless finish that hides minor imperfections</p>
                          <p className='mb-1 texts'> 3. Furniture made out <b>RMI Manufacture solid wooden finger joint board </b>has an attractive looking. It is smooth to touch and has a rich brown color
                            after polishing (Only Gold Section Plank) in comparison to furniture made out of plywood or particle boards.
                          </p>
                          <p className='mb-1 texts'> 4. Durability: <b>RMI Manufacture solid wooden finger joint board </b> furniture can last for up to many years, some have not perished for as long as 100 years. Besides, it doesn't get damaged while shifting places because of water.</p>
                          <p className='mb-1 texts'> 5. Resistant to rot and decay: Furniture made of: RMI <b>Manufacture solid wooden finger joint board</b> is durable and does not rot because of its high density. Good wood furniture should stay in proper shape for a long time</p>

                        </div>
                      </div>
                    </div>


                  </div>

                  <a href="finger-joint-board" className="woodenfloorberhampore mt-2">Learn More</a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <a href='Woodenfloor'>
                  <img src={Woodenfloor} className="card-img-top" alt="Wooden_floor" />
                </a>
                <div className="card-body">
                  <h5 className="card-title">Wooden Floor</h5>
                  <p style={{ color: "grey" }} className="card-text">For use all type of furniture, door and window</p>
                  <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Benefits of Use
                        </button>
                      </h2>
                      <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">

                          <p className='mb-1 texts'>1.Immune to Decay: It is resistant to the attack of fungus, white ants and inscets.</p>
                          <p className='mb-1 texts'>2. It gives a flawless finish that hides minor imperfections</p>
                          <p className='mb-1 texts'> 3. Furniture made out <b>RMI Manufacture solid wooden finger joint board </b>has an attractive looking. It is smooth to touch and has a rich brown color
                            after polishing (Only Gold Section Plank) in comparison to furniture made out of plywood or particle boards.
                          </p>
                          <p className='mb-1 texts'> 4. Durability: <b>RMI Manufacture solid wooden finger joint board </b> furniture can last for up to many years, some have not perished for as long as 100 years. Besides, it doesn't get damaged while shifting places because of water.</p>
                          <p className='mb-1 texts'> 5. Resistant to rot and decay: Furniture made of: RMI <b>Manufacture solid wooden finger joint board</b> is durable and does not rot because of its high density. Good wood furniture should stay in proper shape for a long time</p>

                        </div>
                      </div>
                    </div>


                  </div>
                  <a href="/Woodenfloor" className="woodenfloorberhampore mt-2">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <Fingerdoor />
      <br />
      <br />
      <Woodenpage />
      <br />
      <Mapgoogle />
      <br />
      <br />
    </div>
  )
}

export default Main